import CmsSections from "../components/cmsSections"
import Seo from "../components/shared/seo"
import { fetchAPI } from "../lib/api"
import { HomeProps } from "../types/pages"

const Home = ({ seo, components }: HomeProps) => (
  <>
    <Seo seo={seo} />
    <CmsSections data={components} />
  </>
)

export const getStaticProps = async () => {
  // Fetch global site settings from Strapi
  const globalRes = await fetchAPI("/global")

  const global = globalRes?.data
  const homepage = global?.homepage?.slug

  const pageRes = await fetchAPI(`/pages/${homepage}`)

  if (pageRes.error) {
    return {
      notFound: true,
      revalidate: 5
    }
  }

  return {
    props: { ...pageRes },
    revalidate: 21600,
  }
}

export default Home