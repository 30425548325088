import { useContext } from "react"

import Head from "next/head"

import { getStrapiMedia } from "../../lib/media"
import { GlobalContext } from "../../pages/_app"

const Seo = ({ seo }) => {
  const { default_seo, siteName } = useContext(GlobalContext)
  
  let seoWithDefaults = {
    ...default_seo
  }

  if (Object.keys(seo).length > 0) {
    seoWithDefaults = {
      ...seoWithDefaults,
      ...seo
    }
  }

  const fullSeo = {
    ...seoWithDefaults,
    // Add title suffix
    metaTitle: `${seoWithDefaults.metaTitle} | ${siteName}`,
    // Get full image URL
    metaImage: getStrapiMedia(seoWithDefaults.metaImage),
  }

  return (
    <Head>
      {fullSeo.metaTitle && (
        <>
          <title>{fullSeo.metaTitle}</title>
          <meta property="og:title" content={fullSeo.metaTitle} />
          <meta name="twitter:title" content={fullSeo.metaTitle} />
        </>
      )}
      {fullSeo.keywords && (
        <meta name="keywords" content={fullSeo.keywords} />
      )}
      {fullSeo.metaDescription && (
        <>
          <meta name="description" content={fullSeo.metaDescription} />
          <meta property="og:description" content={fullSeo.metaDescription} />
          <meta name="twitter:description" content={fullSeo.metaDescription} />
        </>
      )}
      {fullSeo.metaImage && (
        <>
          <meta property="og:image" content={fullSeo.metaImage} />
          <meta name="twitter:image" content={fullSeo.metaImage} />
          <meta name="image" content={fullSeo.metaImage} />
        </>
      )}
      {fullSeo.structuredData && (
        <script type="application/ld+json">{JSON.stringify(fullSeo.structuredData)}</script>
      )}
      {fullSeo.canonicalURL && (
        <link rel="canonical" href={fullSeo.canonicalURL} />
      )}
      {fullSeo.article && <meta property="og:type" content="article" />}
      <meta name="twitter:card" content="summary_large_image" />
    </Head>
  )
}

export default Seo